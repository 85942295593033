















import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
const StatististicsStore = namespace('StatististicsStore')

import BaseTitle from '@/components/base/BaseTitle.vue'
import Spinner from '@/components/Spinner.vue'
import StatisticsCharts from '@/modules/components/StatisticsCharts.vue'
import { UserModel } from '@/core/models/UserModel'
import TopBanner from "@/components/banners/TopBanner.vue";
import PremiumStatistics from "@/modules/components/PremiumStatistics.vue";

const UserStore = namespace('UserStore')

@Component({
  name: 'Dashboards',
  components: {
    PremiumStatistics,
    BaseTitle,
    Spinner,
    StatisticsCharts,
    TopBanner
  },
  metaInfo: {
    title: 'Статистика',
  },
})
export default class DashboardsPage extends Vue {
  @StatististicsStore.Action
  private getStatististics!: () => Promise<void>

  @StatististicsStore.Action
  private getPremiumStatististics!: () => Promise<void>

  @StatististicsStore.Action
  private getBannerStatististics!: (id: any) => Promise<void>

  @UserStore.Getter
  public currentUser!: UserModel

  loading = false

  public async created(): Promise<void> {
    this.loading = true
    await this.getStatististics()
    await this.getBannerStatististics(this.currentUser.requisites.id)
    await this.getPremiumStatististics()
    this.loading = false

  }
}
